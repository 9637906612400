<template>
  <div class="uello-app-request h-100" style="margin-top: 20px">
    <div
      class="d-flex align-items-center w-100 px-3"
      :class="selected ? 'justify-content-between' : 'justify-content-start'"
    >
      <!--h2 class="text-dark-darken pl-3 pt-4 mb-4 font-weight-bold">
        {{ $t('pageTitles.request') }}
      </h2-->
      <uello-button
        class="mr-3 pr-3"
        @click="returnToFileSelect"
        style="margin-top:65px"
        v-if="selected"
      >
        <uello-icon color="white" icon="chevron-left" /> {{ $t('requestItems.toBackButton') }}
      </uello-button>
    </div>
    <describe-page
      @selected="setSelected"
      @verify="setVerify"
      @show-form="setShowForm"
      @cancel-solicitation="returnToFileSelect"
      :selectedFileType="selected"
      :verifyFiles="verifyFiles"
      :showForm="showForm"
    />
    <movidesk-modal
      :hasButton="movideskHasButton"
      :is-open="movideskIsOpen"
      :prefill="{}"
      @close="closeMovidesk"
      @open="openMovidesk"
    />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { UelloButton, UelloIcon } from '@uello/componentello';
import * as types from '@/store/types';
import { safeStorage } from '@/utils';
import { movidesk } from '@/mixins';

export default {
  name: 'uello-app-request',
  mixins: [movidesk],
  data() {
    return {
      movideskHasButton: true,
      selected: '',
      verifyFiles: false,
      showForm: false,
    };
  },
  components: {
    DescribePage: () => import('./components/u-request/DescribePage.vue'),
    MovideskModal: () => import('@/components/MovideskTicketFormModal.vue'),
    UelloButton,
    UelloIcon,
  },
  created() {
    const { has_childs } = safeStorage.getItem('config');

    if (has_childs) {
      this.setPartnerChilds();
    }
  },
  methods: {
    ...mapActions({
      setPartnerChilds: types.ACTION_PARTNER_CHILDS,
    }),
    setSelected(value) {
      this.selected = value;
    },
    returnToFileSelect() {
      this.selected = '';
      this.verifyFiles = false;
      this.showForm = false;
    },
    setVerify() {
      this.verifyFiles = true;
    },
    setShowForm() {
      this.showForm = true;
    },
  },
};
</script>

<style></style>
